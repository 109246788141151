import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import DatePicker from "react-datepicker";
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {useState} from 'react';
import Summary from './Summary';
import moment from 'moment';

function ReceiveStockOrderModal(props) {
  
  const initialState = props.item

  const [state, setState] = useState(initialState);

  const handleClose = () => {
    props.closeReceiveModal()
  }
  
  const handleSubmit = () => {
    let params = {...state}

    params["action_type"] = "delivered"

    if (!params["delivered_date"])
      params["delivered_date"] = moment().format()

    props.updateStockOrder(params)
    handleClose()
  }

  const handleDeliveredDateChange = (event) => {
    setState({...state, delivered_date: event });
  }

  const handleInputChange = (event) => {
    if(event.target.getAttribute("assigned") == "remove_file")
      setState({...state, [event.target.name]: "remove" });
    else
      setState({...state, [event.target.name]: event.target.value });
  }

  const {modal_delivered_open, customer_id} = props;
  const {delivered_date} = state;
  const {files} = props.item
  
  return (
    <div className="modal_receive_stock_order_container">
      <Modal isOpen={modal_delivered_open}>
        <ModalHeader >
          <h4 className="modal-title form_new_edit no-margin">Receive Stock Order</h4>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col">
                <Form.Group as={Row} className="mb-3" controlId="formDeliveredDate">
                  <Form.Label column sm="4">
                    <div className="row">
                      <div className="col-12">Delivered date</div>
                      {/* <div className="col-1 p-0">
                        <OverlayTrigger
                            key="delivered_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-delivered_date`}>
                                Delivered date
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div> */}
                    </div>
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker className="form-control"
                              name='delivered_date'
                              id='delivered_date'
                              onChange={handleDeliveredDateChange}
                              selected = {Date.parse(delivered_date ?  moment(delivered_date, props.date_format.toUpperCase()) : moment())}
                              value =  {Date.parse(delivered_date ?  moment(delivered_date, props.date_format.toUpperCase()) : moment())}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
              </div>
              <div className="col">
                <Summary files={files}
                          handleInputChange = {handleInputChange}
                          uploadFile = {props.uploadFile}
                          customer_id = {customer_id}
                          state = {state}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
        <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Receive Stock Order
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default ReceiveStockOrderModal