import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Row, Form, Col, Table, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {useState} from 'react';
import Summary from './Summary';
import moment from 'moment';

function ShipDetailsStockOrderModal(props) {

  const initialState = props.item

  const [state, setState] = useState(initialState);

  const handleRadioInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.value });
  }

  const handleClose = () => {
    props.closeShipDetailsModal();
  }
  
  const handleSubmit = () => {
    let params = {...state}

    props.updateStockOrder(params)
    handleClose()
  }


  const handleCancelDateChange = (event) => {
    setState({...state, end_date: event });
  }

  const handleStartDateChange = (date) => {
    let updates = { start_date: date }
    
    //initialze end date 30 days after start date
    if(!state.end_date && date){
      let new_cancel_date = new Date(date)

      new_cancel_date.setDate(date.getDate() + 30)
      updates["end_date"] = new_cancel_date
    }
    setState({...state, ...updates});
  }

  const handleShipDateChange = (event) => {
    setState({...state, expected_ship_date: event });
  }

  const handleDeliveryDateChange = (event) => {
    setState({...state, expected_delivery_date: event });
  }


  const handleShippingSelectChange = (event) => {
    setState({...state, shipping_company: event.value });
  }

  const handleInputChange = (event) => {
    if(event.target.getAttribute("assigned") == "remove_file")
      setState({...state, [event.target.name]: "remove" });
    else
      setState({...state, [event.target.name]: event.target.value });
  }

    const {modal_ship_details_open, carrier_options, customer_id} = props;

    const {vendor_confirmation_number, start_date, end_date, ordered_date,
          expected_delivery_date, expected_ship_date, shipping_company, tracking_number, tracking_link
          } = state;

    const {files} = props.item
    return (
      modal_ship_details_open && <div className="modal_ship_details_stock_order_container">
        <Modal isOpen={modal_ship_details_open}>
          <ModalHeader >
            <h4 className="modal-title form_new_edit no-margin">Shipping Details</h4>
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col">
                  <Form.Group as={Row} className="mb-3" controlId="formInPersonVCN" >
                    <Form.Label column sm="4">
                      Vendor Confirmation Number
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control type="text" 
                                    name='vendor_confirmation_number'
                                    onChange={handleInputChange}
                                    disabled={props.view_only_stock_orders}
                                    value={vendor_confirmation_number || ""}
                                    />
                    </Col>
                  </Form.Group>
                    <Row>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Shipping Details</Accordion.Header>
                            <Accordion.Body>
                            <Form.Group as={Row} className="mb-3" controlId="formInPersonShipDeliveryDate" >
                              <Form.Label column sm="2">
                                <div className="row">
                                  <div className="col-12">Expected Ship Date</div>
                                  {/* <div className="col-1 p-0">
                                    <OverlayTrigger
                                        key="expected_ship_date"
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-expected_ship_date">
                                            Expected Ship Date
                                          </Tooltip>
                                        }
                                      >
                                      <i className=" align-middle gga-info-circle tooltip_info"/>
                                    </OverlayTrigger>
                                  </div> */}
                                </div>
                              </Form.Label>
                              <Col sm="4">
                                <DatePicker className="form-control"
                                          name='expected_ship_date'
                                          id='expected_ship_date'
                                          onChange={handleShipDateChange}
                                          minDate={Date.parse(moment(ordered_date))}
                                          selected = {Date.parse(moment(expected_ship_date))}
                                          value =  {Date.parse(moment(expected_ship_date))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          disabled={props.view_only_stock_orders}
                                          autoComplete = "off"
                                          />
                              </Col>
                              <Form.Label column sm="2">
                                <div className="row">
                                  <div className="col-12">Expected Delivery Date</div>
                                  {/* <div className="col-1 p-0">
                                    <OverlayTrigger
                                        key="expected_delivery_date"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-expected_delivery_date`}>
                                            Expected Delivery Date
                                          </Tooltip>
                                        }
                                      >
                                      <i className=" align-middle gga-info-circle tooltip_info"/>
                                    </OverlayTrigger>
                                  </div> */}
                                </div>
                              </Form.Label>
                              <Col sm="4">
                                <DatePicker className="form-control"
                                          name='expected_delivery_date'
                                          id='expected_delivery_date'
                                          onChange={handleDeliveryDateChange}
                                          minDate={Date.parse(moment(ordered_date))}
                                          selected = {Date.parse(moment(expected_delivery_date))}
                                          value =  {Date.parse(moment(expected_delivery_date))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          disabled={props.view_only_stock_orders}
                                          autoComplete = "off"
                                          />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formInShippingDetails" >
                              <Form.Label column sm="2">
                                Shipping Company
                              </Form.Label>
                              <Col sm="4">
                                <Select id = "shipping_company" 
                                            name = 'shipping_company'
                                            value={carrier_options.filter(function(option) {
                                              return option.value === shipping_company;
                                            })}
                                            options={carrier_options} 
                                            isDisabled={props.view_only_stock_orders}
                                            onChange={handleShippingSelectChange}/>
                              </Col>
                              <Form.Label column sm="2">
                                Tracking Number
                              </Form.Label>
                              <Col sm="4">
                                <Form.Control type="text" 
                                              name='tracking_number'
                                              disabled={props.view_only_stock_orders}
                                              onChange={handleInputChange} 
                                              value={tracking_number || ""}
                                              />
                              </Col>
                            </Form.Group>
                            {tracking_link && <Form.Group as={Row} className="" controlId="formlink" >
                              <Form.Label column sm="4">
                                Link for tracking
                              </Form.Label>
                              <Col sm="4">
                                <a className="link" href={tracking_link} target= "_blank"> Click for tracking</a>
                              </Col>
                            </Form.Group>}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Row>
                  </div>
                  <div className="col">
                    <Summary files={files}
                              handleInputChange = {handleInputChange}
                              uploadFile = {props.uploadFile}
                              customer_id = {customer_id}
                              view_only_stock_orders = {props.view_only_stock_orders}
                              state = {state}
                    />
                  </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
                className="float-start btn gems_custom_button"
                aria-label="Close"
                onClick={handleClose}
              >
                Close
              </button>
              {!props.view_only_stock_orders && <button
                className="btn gems_custom_button gems_orange_button float-end"
                onClick={handleSubmit}
              >
                Update
            </button>}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
export default ShipDetailsStockOrderModal;