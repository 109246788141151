import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import {useState} from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import Summary from './Summary';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';


function PlaceStockOrderModal(props) {

  const initialState = props.item

  const [state, setState] = useState(initialState);

  const handleRadioInputChange = (event) => {
    if(event.target.value == "email")
      setState({...state, [event.target.name]: event.target.value, ["start_date"]: props.item.start_date, ["end_date"]: props.item.end_date,
      ["expected_delivery_date"]: props.item.expected_delivery_date, ["expected_ship_date"]: props.item.expected_ship_date,
      ["shipping_company"]: props.item.shipping_company, ["tracking_number"]: props.item.tracking_number,
      ["vendor_confirmation_number"]: props.item.vendor_confirmation_number });
    else
      setState({...state, [event.target.name]: event.target.value });
  }

  const handleClose = () => {
    props.closePlaceModal();
  }

  const handleSubmit = () => {
    let params = {...state}
    params["ordered_date"] = moment().format()
    params["action_type"] = "place"

    props.updateStockOrder(params)
    handleClose()
  }

  const handleHtmlChange = (event) => {
    setState({...state, body: event });
  }

  const handleCancelDateChange = (event) => {
    setState({...state, end_date: event });
  }

  const handleStartDateChange = (date) => {
    let updates = { start_date: date }

    //initialze end date 30 days after start date
    if(!state.end_date && date){
      let new_cancel_date = new Date(date)

      new_cancel_date.setDate(date.getDate() + 30)
      updates["end_date"] = new_cancel_date
    }
    setState({...state, ...updates});
  }

  const handleShipDateChange = (event) => {
    setState({...state, expected_ship_date: event });
  }

  const handleDeliveryDateChange = (event) => {
    setState({...state, expected_delivery_date: event });
  }


  const handleShippingSelectChange = (event) => {
    setState({...state, shipping_company: event.value });
  }

  const handleInputChange = (event) => {
    if(event.target.getAttribute("assigned") == "remove_file")
      setState({...state, [event.target.name]: "remove" });
    else
      setState({...state, [event.target.name]: event.target.value });
  }

    const {modal_place_open, carrier_options, customer_id} = props;
    const {end_date, start_date, method_of_ordering, vendor_confirmation_number,
          expected_delivery_date, expected_ship_date, shipping_company, tracking_number,
          to, subject, body, vendor_details} = state;

    const {files} = props.item

    const config_froala = {
      placeholderText: 'Edit Your Content Here!',
      charCounterCount: false,
      key: 'aLF3c1C9C7A5D3F2H3C-7SLJCKHXOSLMc2YGSGe1ZXHSa1CgC3E3G3B2B7A4B3F4E2B2==',
      attribution: false,
      quickInsertButtons: ['image', 'video', 'table', 'ul', 'ol', 'hr'],
      toolbarButtons: {
        'moreRich': {
          'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertFile', 'insertHR'],
        },
        'moreMisc': {},
        'moreText': {

          'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']

        },

        'moreParagraph': {

          'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']

        }
      },
    }

    return (
      modal_place_open && <div className="modal_place_stock_order_container" >
        <Modal isOpen={modal_place_open}>
          <ModalHeader >
            <h4 className="modal-title form_new_edit no-margin">Place Stock Order</h4>
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="row m-0">
                <div className="col overflow-auto">
                  <Form.Group as={Row} className="" controlId="formMethodOfPlacingTitle">
                    <Form.Label className="p-0">
                      SEND STOCK ORDER BY:
                    </Form.Label>
                  </Form.Group>
                  <div key="inline-formMethodOfPlacing" className="mb-3 row">
                    <Form.Check
                      inline
                      name="method_of_ordering"
                      value = "in_person"
                      type='radio'
                      id="in_person"
                      label="In Person"
                      className="col"
                      ini={true.toString()}
                      checked = {method_of_ordering == "" || method_of_ordering == undefined || method_of_ordering == "in_person"}
                      onChange={handleRadioInputChange}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      name="method_of_ordering"
                      value = "phone"
                      id="phone"
                      label="Phone"
                      className="col"
                      checked = {method_of_ordering == "phone"}
                      onChange={handleRadioInputChange}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      name="method_of_ordering"
                      value = "b2b"
                      id="b2b"
                      label="B2B"
                      className="col"
                      checked = {method_of_ordering == "b2b"}
                      onChange={handleRadioInputChange}
                    />
                    <Form.Check
                      inline
                      type='radio'
                      name="method_of_ordering"
                      value = "email"
                      id="email"
                      label="Email"
                      className="col"
                      checked = {method_of_ordering == "email"}
                      onChange={handleRadioInputChange}
                    />
                  </div>

                  { (method_of_ordering == "phone" || method_of_ordering == "b2b") &&
                  <Row className="mb-3">
                    <Form.Label column>
                      Vendor Account#: {vendor_details["account_number"] || "No Account#"}
                    </Form.Label>

                    <Form.Label column>
                      Vendor Phone: {vendor_details["phone"] || "No Phone"}
                    </Form.Label>

                    <Form.Label column>
                      Vendor Email: {vendor_details["email"] || "No Email"}
                    </Form.Label>
                  </Row>
                  }
                  { (method_of_ordering != "email") &&
                  <div className="no_email_inputs_container collect_information_class" id ="no_email_inputs_container">
                    <Form.Group as={Row} className="mb-3" controlId="formInPersonVCN" >
                      <Form.Label column sm="4">
                        Vendor Confirmation Number
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control type="text"
                                      name='vendor_confirmation_number'
                                      onChange={handleInputChange}
                                      value={vendor_confirmation_number || ""}
                                      />
                      </Col>
                    </Form.Group>
                    <Row className="pb-3">
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Card.Header>
                            Shipping window
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Form.Group as={Row} controlId="formInPersonStartCancelDate" >
                                <Form.Label column sm="2">
                                  <div className="row">
                                    <div className="col-11">Start Ship Date</div>
                                    <div className="col-1 p-0">
                                      <OverlayTrigger
                                          key="start_date"
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-start_date`}>
                                              This is the earliest date that you will accept the shipment of the order. Confirm with your vendor if unknown.
                                            </Tooltip>
                                          }
                                        >
                                        <i className=" align-middle gga-info-circle tooltip_info"/>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </Form.Label>
                                <Col sm="4">
                                  <DatePicker className="form-control"
                                            name='start_date'
                                            id='start_date'
                                            onChange={handleStartDateChange}
                                            minDate={new Date()}
                                            selected = {Date.parse(moment(start_date))}
                                            value =  {Date.parse(moment(start_date))}
                                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                                            autoComplete = "off"
                                            />
                                </Col>
                                <Form.Label column sm="2">
                                  <div className="row">
                                    <div className="col-11">End Ship Date</div>
                                    <div className="col-1 p-0">
                                      <OverlayTrigger
                                          key="end_date"
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-end_date`}>
                                              This is the date that you require that the order is shipped by. If it is not, the order must be cancelled by the vendor. Confirm with your vendor if unknown.
                                            </Tooltip>
                                          }
                                        >
                                        <i className=" align-middle gga-info-circle tooltip_info"/>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </Form.Label>
                                <Col sm="4">
                                  <DatePicker className="form-control"
                                            name='end_date'
                                            id='end_date'
                                            onChange={handleCancelDateChange}
                                            minDate={new Date()}
                                            selected = {Date.parse(moment(end_date))}
                                            value =  {Date.parse(moment(end_date))}
                                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                                            autoComplete = "off"
                                            />
                                </Col>
                              </Form.Group>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Row>
                    <Row className="" style={{ marginBottom: 80 }}>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Shipping Details</Accordion.Header>
                            <Accordion.Body>
                            <Form.Group as={Row} className="mb-3" controlId="formInPersonShipDeliveryDate" >
                              <Form.Label column sm="2">
                                <div className="row">
                                  <div className="col-12">Expected Ship Date</div>
                                  {/* <div className="col-1 p-0">
                                    <OverlayTrigger
                                        key="expected_ship_date"
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-expected_ship_date">
                                            Expected Ship Date
                                          </Tooltip>
                                        }
                                      >
                                      <i className=" align-middle gga-info-circle tooltip_info"/>
                                    </OverlayTrigger>
                                  </div> */}
                                </div>
                              </Form.Label>
                              <Col sm="4">
                                <DatePicker className="form-control"
                                          name='expected_ship_date'
                                          id='expected_ship_date'
                                          onChange={handleShipDateChange}
                                          minDate={new Date()}
                                          selected = {Date.parse(moment(expected_ship_date))}
                                          value =  {Date.parse(moment(expected_ship_date))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          autoComplete = "off"
                                          />
                              </Col>
                              <Form.Label column sm="2">
                                <div className="row">
                                  <div className="col-12">Expected Delivery Date</div>
                                  {/* <div className="col-1 p-0">
                                    <OverlayTrigger
                                        key="expected_delivery_date"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-expected_delivery_date`}>
                                            Expected Delivery Date
                                          </Tooltip>
                                        }
                                      >
                                      <i className=" align-middle gga-info-circle tooltip_info"/>
                                    </OverlayTrigger>
                                  </div> */}
                                </div>
                              </Form.Label>
                              <Col sm="4">
                                <DatePicker className="form-control"
                                          name='expected_delivery_date'
                                          id='expected_delivery_date'
                                          onChange={handleDeliveryDateChange}
                                          minDate={new Date()}
                                          selected = {Date.parse(moment(expected_delivery_date))}
                                          value =  {Date.parse(moment(expected_delivery_date))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          autoComplete = "off"
                                          />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formInShippingDetails" >
                              <Form.Label column sm="2">
                                Shipping Company
                              </Form.Label>
                              <Col sm="4">
                                <Select id = "shipping_company"
                                            name = 'shipping_company'
                                            value={carrier_options.filter(function(option) {
                                              return option.value === shipping_company;
                                            })}
                                            options={carrier_options}
                                            onChange={handleShippingSelectChange}/>
                              </Col>
                              <Form.Label column sm="2">
                                Tracking Number
                              </Form.Label>
                              <Col sm="4">
                                <Form.Control type="text"
                                              name='tracking_number'
                                              onChange={handleInputChange}
                                              value={tracking_number || ""}
                                              />
                              </Col>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Row>
                  </div>
                  }
                  { (method_of_ordering == "email") && <div>
                    <Form.Group as={Row} className="ps-3 pb-3" controlId="formInEmailTo" >
                      <Form.Label column sm="2">
                        TO:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control type="text"
                                      name='to'
                                      onChange={handleInputChange}
                                      value={to || ""}
                                      />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="ps-3 pb-3" controlId="formInEmailSbj" >
                      <Form.Label column sm="2">
                        SUBJECT:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control type="text"
                                      name='subject'
                                      onChange={handleInputChange}
                                      value={subject || ""}
                                      />
                      </Col>
                    </Form.Group>
                  <FroalaEditor tag='textarea'
                                config={config_froala}
                                model={body}
                                onModelChange={handleHtmlChange}/></div>
                  }
               </div>
                <div className="col">
                  <Summary files={files}
                            handleInputChange = {handleInputChange}
                            uploadFile = {props.uploadFile}
                            customer_id = {customer_id}
                            state = {state}
                  />
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
                className="float-start btn gems_custom_button"
                aria-label="Close"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                className="btn gems_custom_button gems_orange_button float-end"
                onClick={handleSubmit}
              >
                Place Stock Order
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
export default PlaceStockOrderModal;